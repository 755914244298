import * as React from 'react'
import { Box } from '@chakra-ui/react'
import GroupsPage from '../pagesComponents/groups'
import Dashboard from './dashboard'
import Layout from '../layout'
import Base from '../base'
import Login from '../pagesComponents/login'

const App = () => {
	return (
		<Layout theme="light" title="Dashboard">
			<Login />
		</Layout>
	)
}

export default App
